import React from "react";
import "./Nft.css";
import nftVideo from "../image/takacoinnft.mp4"; // Update the path if needed

const Nft = () => {
  return (
    <div className="glowing-card">
      <video src={nftVideo} autoPlay loop muted className="nft-video"></video>
      <h2 className="nft-title">Taka Coin NFT Mining</h2>
      <a
        href="https://opensea.io/assets/matic/0xb2cb391cc44decae7f94c3130a84e66ea6537510/2"
        target="_blank"
        rel="noopener noreferrer"
        className="buy-button"
      >
        Buy Now
      </a>
    </div>
  );
};

export default Nft;
