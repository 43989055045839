import React from 'react';
import './UserManual.css';
import screenshot1 from '../image/1.jpg'; // Replace path if necessary
import screenshot2 from '../image/2.jpg'; // Replace path if necessary
import screenshot3 from '../image/3.jpg'; // Replace path if necessary

const UserManual = () => {
  return (
    <section className="user-manual">
      <div className="container">
        <h1>User Manual</h1>
        <p>Welcome to Taka Coin! This guide will walk you through the process of requesting tokens, checking the Initial Coin Offering (ICO) countdown, and using our platform.</p>
        
        <h2>Getting Started with Taka Coin</h2>
        <p>To begin using Taka Coin and participate in the ICO, follow these steps:</p>
        
        <ol>
          <li>
            <strong>Connect Your Wallet:</strong> 
            <p>On the homepage, click the <em>Get Started</em> button. This will prompt you to connect your wallet using Phantom. If you haven't installed Phantom, a message will appear asking you to do so.</p>
          </li>

          <li>
            <strong>Switch to the Correct Network:</strong> 
            <p>Once connected, ensure you are on the <em>Solana Blockchain</em> network. If you are not, the platform will automatically add the network for you. Simply approve the network switch when prompted.</p>
          </li>

          <li>
            <strong>Request Tokens:</strong> 
            <p>After connecting your wallet, enter the amount of Taka Coin you wish to request. The system will fetch the equivalent USDT amount you need to pay from Binance. Once the USDT price is fetched, click on <em>Request Tokens</em> to proceed.</p>
          </li>
        </ol>

        <h2>Screenshots of the Process</h2>
        <p>Below are some screenshots to guide you through the process visually:</p>

        <div className="screenshots">
          <img src={screenshot1} alt="Wallet Connection" className="screenshot" />
          <img src={screenshot2} alt="Switch Network" className="screenshot" />
          <img src={screenshot3} alt="Request Tokens" className="screenshot" />
        </div>

        <h2>Watch Our Tutorial Video</h2>
        <p>For a detailed walkthrough, watch the tutorial video below:</p>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ExG9x_S2F84?si=gzr9CdMvWbKJ4o_s"
            title="Taka Coin Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <h2>FAQ</h2>
        <p>Here are some common questions you may have while using our platform:</p>
        
        <ul>
          <li><strong>How do I change the network?</strong> 
            <p>The platform will automatically switch you to the correct network. If you need to switch manually, open MetaMask, go to Networks, and select <em>Skale Testnet</em>.</p>
          </li>
          <li><strong>What if I can’t find my transaction ID?</strong> 
            <p>Your transaction ID can be found in the history of your Binance account. Navigate to your recent transactions and find the one related to your Taka Coin purchase.</p>
          </li>
          <li><strong>What happens if my payment is not verified?</strong> 
            <p>If your payment is not verified, double-check your transaction ID and make sure you've completed the payment to the correct Binance ID.</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default UserManual;
